.page {
  position: absolute;
  width:100%;
  height: 100%;
}

.pageTransition-enter {
  opacity: 0;
  transform: translateY(100%);
}
.pageTransition-enter-active {
  z-index: 2;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 1;
  transform: translateY(0px);
}
.pageTransition-exit-active {
  z-index: 1;
}